import { useEffect } from 'react';

function IPVisitor({ pageName }) {
  useEffect(() => {
    // Obtenir l'adresse IP du visiteur
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const visitorIp = data.ip;
        
        // Envoyer l'adresse IP et le nom de la page à l'API Django
        fetch('/api/send-notification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ip: visitorIp, page: pageName })
        })
        .then(response => response.json())
        .then(data => console.log('Notification sent:', data))
        .catch((error) => console.error('Erreur lors de l\'envoi de l\'IP et du nom de la page:', error));
      })
      .catch((error) => console.error('Erreur lors de l\'obtention de l\'IP:', error));
  }, [pageName]); // Ce useEffect se déclenche une seule fois au chargement de la page ou si pageName change

  // Le composant n'a pas besoin de rendre quoi que ce soit visuellement
  return null;
}

export default IPVisitor;
