import React, { useEffect, useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { StyledTreeItem } from './StyledTreeItem';
import treeData from './TreeData';
import containership from '../assets/containership.svg';
import treet from '../assets/treet.png';
import './Sidebar.css';
import { useLocation } from 'react-router-dom';

const renderTreeItems = (nodes, theme, selectedNodeId) => {
  return (
    <StyledTreeItem
      key={nodes.nodeId}
      nodeId={nodes.nodeId}
      label={
        <div style={{ width: '100%' }}> {/* Conteneur pour étendre la zone cliquable */}
          <NavLink
            to={nodes.path}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              width: '100%', // Assurez-vous que le lien occupe toute la largeur
              display: 'block' // Rend le lien comme un bloc pour occuper toute la largeur
            }}
          >
            {nodes.label}
          </NavLink>
        </div>
      }
      style={{ marginTop: theme.spacing(2) }}
      isSelected={selectedNodeId === nodes.nodeId}
    >
      {Array.isArray(nodes.children) &&
        nodes.children.map((node) => renderTreeItems(node, theme, selectedNodeId))
      }
    </StyledTreeItem>
  );
};


const findNodeIds = (nodes, path, ids = []) => {
  for (let node of nodes) {
    if (node.path === path) {
      return [...ids, node.nodeId];
    }
    if (node.children) {
      const foundIds = findNodeIds(node.children, path, [...ids, node.nodeId]);
      if (foundIds) return foundIds;
    }
  }
  return null;
};



const Sidebar = ({ isOpen, toggleSidebar, handleNodeSelect }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = window.innerWidth < 768;


  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [expandedNodeIds, setExpandedNodeIds] = useState([]);


  const handleToggle = (event, nodeIds) => {
    setExpandedNodeIds(nodeIds);
  };


  useEffect(() => {
    const nodeIds = findNodeIds(treeData, location.pathname);
    if (nodeIds) {
      setSelectedNodeId(nodeIds[nodeIds.length - 1]);
      // Conserver les nœuds déjà ouverts et ajouter les parents du nœud sélectionné
      setExpandedNodeIds(prevExpandedIds =>
        Array.from(new Set([...prevExpandedIds, ...nodeIds.slice(0, -1)]))
      );
    }
  }, [location.pathname]);


  return (
    <div className="menu">
      {isMobile && !isOpen && (
        <IconButton onClick={toggleSidebar} className="menu-button" >
          <MenuIcon style={{ color: '#1274AA' }} />
        </IconButton>
      )}


      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>





        <div className="sidebar-content">


          <NavLink to="/" className="logo-link">

           
              <img src={containership} alt="Container Ship" style={{ display: 'inline-Block', width: '80px', maxWidth: '100%', maxHeight: '100px', padding: '15px  18px 15px' }} />

              <img style={{ display: 'inline-Block', width: '80px' }} src={treet} />
 

            
          </NavLink>




          <div className="tree-view-container scustom-scrollbar">


            <TreeView
              className="tree-view"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeSelect={handleNodeSelect}
              onNodeToggle={handleToggle}
              selected={selectedNodeId}
              expanded={expandedNodeIds}
            >

              {treeData.map((node) => renderTreeItems(node, theme, selectedNodeId))}


            </TreeView>
          </div>
        </div>
        {
          isMobile && isOpen && (
            <IconButton onClick={toggleSidebar} className="close-button">
              <CloseIcon style={{ color: '#1274AA' }} />
            </IconButton>
          )
        }
      </div >
    </div>
  );
};

export default Sidebar;
