import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainContent from './main/MainContent';
// Utilisez React.lazy pour les importations dynamiques
// const Aboutme = React.lazy(() => import('./sidebar/routes/AboutMe/AboutMe'));

//Projets

const AGDLP = React.lazy(() => import('./sidebar/routes/Projets/AGDLP/agdlp'));


const ProjetAUtoIntro = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/0_intro'));
const Lab_GNS3_Discover = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/3_Lab_GNS3_Discover'));
const Preparation_gns_lab1 = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/2_preparation'));
const Gns3 = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/1_gns3'));
const Ansible_Setup = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/4_Ansible'));
const Ansible_jenkins = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/5_ansible_jenkins'));
const Netbox = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/6_netbox'));
const Recuperation_depuis_gns3 = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/7_recuperation_depuis_gns3'));
const Intiation_Interface_Management = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/8_Initiation'));
const Automatisation_management = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/9_management'));
const Assemblage_de_processus = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/10_assemblage'));
const NowWhat1 = React.lazy(() => import('./sidebar/routes/Projets/Automatisation/SOT/now_what'));




const Terraform = React.lazy(() => import('./sidebar/routes/Projets/Terraform/terraform'));



//Brouillons
const Brouillon = React.lazy(() => import('./sidebar/routes/admin/documentation/brouillon'));


//Documentation

//Réseau
const Osi = React.lazy(() => import('./sidebar/routes/admin/networking/Osi'));


// const Spine_leaf = React.lazy(() => import('./sidebar/routes/admin/networking/Spine_leaf')); <Route path="/Spine_leaf" element={<Spine_leaf />} />

const SwitchingBases = React.lazy(() => import('./sidebar/routes/admin/networking/switching/SwitchingBases'));
const SpanningTree = React.lazy(() => import('./sidebar/routes/admin/networking/switching/SpannigTree'));

const RouterPassword = React.lazy(() => import('./sidebar/routes/admin/networking/routing/RouterPassword'));
const RouterSsh = React.lazy(() => import('./sidebar/routes/admin/networking/routing/routerSsh'));
const Acl = React.lazy(() => import('./sidebar/routes/admin/networking/routing/acl'));
const HSRP = React.lazy(() => import('./sidebar/routes/admin/networking/routing/HSRP'));
const RIPv2 = React.lazy(() => import('./sidebar/routes/admin/networking/routing/RIPv2'));
const OSPF = React.lazy(() => import('./sidebar/routes/admin/networking/routing/ospf'));



//System
const AWX = React.lazy(() => import('./sidebar/routes/admin/system/linux/Automatisation/awx'));
const Ansible = React.lazy(() => import('./sidebar/routes/admin/system/linux/Automatisation/ansible'));
const Ansible_Vault = React.lazy(() => import('./sidebar/routes/admin/system/linux/Automatisation/ansible_vault'));
const Ansible_Playbooks = React.lazy(() => import('./sidebar/routes/admin/system/linux/Automatisation/ansible_playbooks'));
const Jenkins_ansible = React.lazy(() => import('./sidebar/routes/admin/system/linux/Automatisation/Jenkins_Ansible'));

// Devops
const DevopsLinks = React.lazy(() => import('./sidebar/routes/devops/ci-cd/links'));



const Error10 = React.lazy(() => import('./sidebar/routes/404'));


const RouteConfig = () => {
    return (
        <Routes>
            {/*  <Route path="/Aboutme" element={<Aboutme />} /> */}




            <Route path="/AGDLP" element={<AGDLP />} />



            <Route path="/Projet_4_Introduction" element={<ProjetAUtoIntro />} />
            <Route path="/GNS3" element={<Gns3 />} />
            <Route path="/Preparation_gns_lab1" element={<Preparation_gns_lab1 />} />
            <Route path="/Lab_GNS3_Discover" element={<Lab_GNS3_Discover />} />
            <Route path="/Ansible_Setup" element={<Ansible_Setup />} />
            <Route path="/Job_Jenkins1" element={<Ansible_jenkins />} />
            <Route path="/Netbox" element={<Netbox />} />
            <Route path="/Recuperation_depuis_gns3" element={<Recuperation_depuis_gns3 />} />
            <Route path="/Intiation_Interface_Management" element={<Intiation_Interface_Management />} />
            <Route path="/Automatisation_management" element={<Automatisation_management />} />
            <Route path="/Assemblage_de_processus" element={<Assemblage_de_processus />} />
            <Route path="/NowWhat1" element={<NowWhat1 />} />




            <Route path="/Terraform-EKS-Introduction" element={<Terraform />} />








            <Route path="/Brouillon" element={<Brouillon />} />

            #Réseau
            <Route path="/Model-Osi" element={<Osi />} />
            <Route path="/OSPF" element={<OSPF />} />
            <Route path="/switching-bases" element={<SwitchingBases />} />
            <Route path="/spanning-tree" element={<SpanningTree />} />
            <Route path="/Router-Cisco-Passwords" element={<RouterPassword />} />
            <Route path="/Router-SSH" element={<RouterSsh />} />
            <Route path="/ACL" element={<Acl />} />
            <Route path="/HSRP" element={<HSRP />} />
            <Route path="/RIPv2" element={<RIPv2 />} />



            #Linux
            <Route path="/AWX" element={<AWX />} />
            <Route path="/Ansible" element={<Ansible />} />
            <Route path="/Ansible_Vault" element={<Ansible_Vault />} />
            <Route path="/Ansible_Playbooks" element={<Ansible_Playbooks />} />
            <Route path="/Jenkins_ansible" element={<Jenkins_ansible />} />





            <Route path="/DevopsLinks" element={<DevopsLinks />} />





            <Route path="/" element={<MainContent />} />
            <Route path="*" element={<Error10 />} />
        </Routes>
    );
};

export default RouteConfig;
