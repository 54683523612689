const data = [

  // {
   // nodeId: 'Aboutme',
    //label: 'About me',
    //path: '/Aboutme',
    //children: []

  //},
  {

 
 
        nodeId: 'D0',
        label: 'Brouillon',
        path: '/Brouillon',
        children: []

      },


      {
        nodeId: 'D1',
        label: 'New',
        children: [


          {
            nodeId: 'A',
            label: 'Administrateur',
            children: [

              {
                nodeId: '1000',
                label: 'Réseau',
                isFirstItem: true,
                children: [
                  {
                    nodeId: '1001',
                    label: 'Model Osi',
                    path: '/Model-Osi',
                    children: []
                  },

                  {
                    nodeId: '1100',
                    label: 'Switching',
                    children: [
                      {
                        nodeId: '1010',
                        label: 'Les bases',
                        path: '/switching-bases',
                        children: []
                      },
                      {
                        nodeId: '1020',
                        label: 'Lab Spanning Tree',
                        path: '/spanning-tree',
                        children: []
                      },

                    ]
                  },
                  {
                    nodeId: '1200',
                    label: 'Routing',
                    children: [
                      {
                        nodeId: '1210',
                        label: 'Router Scisco: Passwords ',
                        path: '/Router-Cisco-Passwords',
                        children: []
                      },
                      {
                        nodeId: '1211',
                        label: 'Configuration SSH ',
                        path: '/Router-SSH',
                        children: []
                      },
                      {
                        nodeId: '1212',
                        label: 'Accès: ACL ',
                        path: '/ACL',
                        children: []
                      },
                      {
                        nodeId: '1213',
                        label: 'Protocol HSRP ',
                        path: '/HSRP',
                        children: []
                      },
                      {
                        nodeId: '1214',
                        label: 'Protocol RIP v2',
                        path: '/RIPv2',
                        children: []
                      },
                      {
                        nodeId: '1215',
                        label: 'Protocol OSPF',
                        path: '/OSPF',
                        children: []
                      }
                    ]
                  },
                  {
                    nodeId: '1300',
                    label: 'Firewalling',
                    children: [
                      {
                        nodeId: '1310',
                        label: 'Bientôt disponible',
                        children: []
                      }
                    ]
                  },



                  //  {
                  //   nodeId: '1003',
                  //   label: 'Spine leaf',
                  // path: '/Spine_leaf',
                  //   children: []
                  // }
                ]
              },
              {
                nodeId: '2000',
                label: 'Système',
                children: [
                  {
                    nodeId: '2100',
                    label: 'Windows',
                    children: [
                      {
                        nodeId: '2110',
                        label: 'Bientôt disponible',
                        children: []
                      }
                    ]
                  },
                  {
                    nodeId: '2200',
                    label: 'Linux',
                    children: [
                      {
                        nodeId: '2210',
                        label: 'Automatisation',
                        children: [

                          {
                            nodeId: 'A2211',
                            label: 'Ansiblie',
                            path: '/Ansible',
                            children: []
                          },
                          {
                            nodeId: 'A2212',
                            label: 'Ansible Vault',
                            path: '/Ansible_Vault',
                            children: []
                          },
                          {
                            nodeId: 'A2213',
                            label: 'Ansible Playbooks',
                            path: '/Ansible_Playbooks',
                            children: []
                          },
                          {
                            nodeId: 'A2214',
                            label: 'Ansible AWX',
                            path: '/AWX',
                            children: []
                          },
                          {
                            nodeId: 'A2215',
                            label: 'Jenkins: Plugin ansible',
                            path: '/Jenkins_ansible',
                            children: []
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                nodeId: '3000',
                label: 'Cloud',
                children: [
                  {
                    nodeId: '3100',
                    label: 'Bientôt disponible',
                    children: []
                  }
                ]
              },
              {
                nodeId: '4000',
                label: 'Docker',
                children: [
                  {
                    nodeId: '4100',
                    label: 'Bientôt disponible',
                    children: []
                  }
                ]
              },

              {
                nodeId: '5000',
                label: 'Sécurité',
                children: [
                  {
                    nodeId: '5100',
                    label: 'Bientôt disponible',
                    children: []
                  }
                ]
              }
            ]

          },

          {
            nodeId: 'B',
            label: 'DevOps ',
            children: [
              {
                nodeId: '0000B',
                label: 'CI-CD',
                children: [

                  {
                    nodeId: '0100B',
                    label: 'Liens utiles',
                    path: '/DevopsLinks',
                    children: []
                  }

                ]
              },
              {
                nodeId: '2000B',
                label: 'Terraform',
                children: []
              },

              {
                nodeId: '3000B',
                label: 'Cloud ',
                children: []
              },

              {
                nodeId: '4000B',
                label: 'Python ',
                children: []
              }


            ]
          },
 


        ]
      }
    ] ;

export default data;