import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';  
import IPVisitor from "../composants/chatbot/ipvisitor";

const MainContent = () => {
  const [loading, setLoading] = useState(true);

  // Fonction appelée lorsque l'iframe est chargée
  const handleLoad = () => {
    setLoading(false);
  };

  // Utilisez useEffect pour définir un délai pour masquer le spinner
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 6000); // 6 secondes

    // Fonction de nettoyage pour effacer le timer si l'iframe est chargée avant
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {loading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <CircularProgress /> {/* Spinner de Material-UI */}
        </div>
      )}
      <iframe 
        src="https://hannibal52.github.io/COURS//timer/home.html" 
        title="Switching Bases" 
        style={{ width: '100%', height: '100%', border: 'none', display: loading ? 'none' : 'block' }}
        onLoad={handleLoad}
      />
       <IPVisitor pageName="landing " />
    </div>
  );
};

export default MainContent;