// StyledTreeItem.js
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { styled } from '@mui/material/styles';


export const StyledTreeItem = styled(TreeItem)(({ theme, isSelected }) => ({

  '& .MuiTreeItem-iconContainer': {
    color: isSelected ? '#ffffff' : '#66C3F7', // Bleu par défaut, blanc lorsque sélectionné
  
  },
  '& .MuiTreeItem-iconContainer:not(.selected-icon)': {
    animation: 'pulseColor 2s infinite',
  },

  '& .MuiTreeItem-content': {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#fff',
    backgroundColor: isSelected ? '#fff' : 'transparent',
    color: isSelected ? '#fff' : '#fff',
  

    '&:hover, &.Mui-focused, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected': {
      backgroundColor: '#1274AA !important',
      borderRadius: '5px',
    },
    '&:hover': {
      backgroundColor: '#29292A',
      borderRadius: '5px',
    },
  },
  '&.firstItem': {
    marginTop: theme.spacing(1),
  },
  '& .MuiTreeItem-root': {
    marginBottom: theme.spacing(1),
  },
  '& .MuiTreeItem-group': {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeft: `1px dashed ${theme.palette.grey[800]}`, 
  },
  
}));