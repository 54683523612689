import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import RouteConfig from './routes';
import treeData from './sidebar/TreeData'; // Assurez-vous que le chemin d'importation est correct
import useWindowDimensions from './sidebar/useWindowDimensions';
import Chatbot from './composants/chatbot/chatbot';

import './App.css';

function App() {

  const [selectedNodeId, setSelectedNodeId] = useState(null);

  const { width } = useWindowDimensions();

  const isMobile = width < 768;
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

  const handleNodeSelect = (nodeId) => {
    const findNode = (nodes, id) => {
      for (const node of nodes) {
        if (node.nodeId.toString() === id) return node;
        if (node.children) {
          const foundNode = findNode(node.children, id);
          if (foundNode) return foundNode;
        }
      }
      return null;
    };

    const node = findNode(treeData, nodeId);
    console.log(node); // Vérifiez si le nœud est trouvé
    setSelectedNodeId(nodeId);
    if (isMobile && node?.path) {
      setSidebarOpen(false);
    }
  };



  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Router>
      <div className="App">
      
        <Sidebar
          handleNodeSelect={handleNodeSelect}
          isOpen={sidebarOpen}
          selectedNodeId={selectedNodeId}
          toggleSidebar={toggleSidebar}
          treeData={treeData} // Passez les données de l'arbre à la sidebar
          isMobile={isMobile}
        />
        <div className={`main-content ${sidebarOpen ? 'sidebar-open' : ''}`}>
          <Suspense fallback={<div>Chargement...</div>}>
            <RouteConfig />
          </Suspense>
        </div>
       

      </div>
    </Router>
  );
}

export default App;
